import { navigate, Link, useStaticQuery, graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

import LoadingPrimaryButton from 'components/Buttons/LoadingPrimaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Cart from 'components/Cart/Cart';
import CheckoutForm from 'components/CheckoutForm/CheckoutForm';
import Circle from 'components/Circle/Circle';
import Container from 'components/Container/Container';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Copyrights from 'components/Footer/Copyrights';
import Logo from 'components/Icons/Logo';
import Seo from 'components/Seo';

import { useCartContext } from 'hooks/useCartContext';
import { useCash } from 'hooks/useCash';
import useRequest from 'hooks/useRequest';

import { useAuthUser } from 'utilities/AuthUser';
import { isUnavailable, isLessAvailable } from 'utilities/product';

import api from 'api/app';

import { btnLinkSecondary } from 'styles/components/links.module.scss';
import {
  fullHeightContainer,
  brand,
  header,
  title,
  container,
  leftContainer,
  rightContainer,
  error,
  infoText,
  titleContainer,
  simpleContainer,
  signupContainer,
  signupTitle,
  formContainer,
  btnCheckout,
} from 'styles/pages/checkout.module.scss';

const circleStyle = {
  width: '1.75rem',
  height: '1.75rem',
  background: '#000',
  color: '#fff',
  marginLeft: '0.5rem',
};

const Checkout = () => {
  const { isLoggedIn, loading, user } = useAuthUser();
  const { isCash, setIsCash } = useCash();
  const { cart, emptyCart, removeItem, adjustItem, totals } = useCartContext();
  const [quantities, setQuantities] = useState({});
  const [isCartEligible, setIsCartEligible] = useState(true);
  const [isAnyUnavailable, setIsAnyUnavailable] = useState(false);
  const [isAnyLessAvailable, setIsAnyLessAvailable] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const coupon = useRequest();
  const { site } = useStaticQuery(graphql`
    query Site {
      site {
        siteMetadata {
          site
          title
        }
      }
    }
  `);

  const submitOrder = (data) => {
    const { address, companyAddress, ...rest } = data;
    // user is logged in - determine if he entered new address
    const save = !!(address && address.value === '');
    setOrderError(false);
    setIsLoading(true);
    const formData = {
      ...rest,
      ...address,
      ...companyAddress,
      save,
    };
    delete formData.label;
    delete formData.value;
    let url = `/orders${isLoggedIn ? '' : '/guest'}`;
    api
      .post(url, {
        ...formData,
        products: cart.map((item) => ({
          _id: item.id,
          quantity: item.quantity,
          size:
            item.product && item.product.size ? item.product.size : undefined,
        })),
        coupon: coupon.data && coupon.data.code ? coupon.data.code : undefined,
      })
      .then(({ data }) => {
        if (typeof window !== 'undefined') {
          if (data.paymentType === 'card') {
            window.location.replace(data.paymentUrl);
          } else {
            navigate(`/payment/success?orderId=${data.friendlyId}`);
          }
        }
      })
      .catch(() => {
        setOrderError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const submitCoupon = (code) => {
    coupon.fetch(`/coupons/check/${code}`);
  };

  useEffect(() => {
    let mounted = true;
    const checkQuantity = async (ids) => {
      try {
        const { data } = await api.get(
          `/products/quantity?ids=${ids.join(',')}`
        );
        setQuantities(data);
        setIsCartEligible(
          !cart.every((item) => {
            return isUnavailable(item.id, data);
          })
        );
        setIsAnyUnavailable(
          cart.find((item) => {
            return isUnavailable(item.id, data);
          })
        );
        setIsAnyLessAvailable(
          cart.find((item) => {
            return isLessAvailable(item.id, item.quantity, data);
          })
        );
      } catch (e) {
        console.log(e);
      }
    };
    if (cart) {
      const ids = cart.map((item) => {
        return item.id;
      });
      ids.length > 0 && mounted && checkQuantity(ids);
    }
    return () => (mounted = false);
  }, [cart]);

  const emptyAndContinue = () => {
    emptyCart();
    if (typeof window !== 'undefined') {
      navigate('/');
    }
  };

  const handleCartChanges = () => {
    cart.forEach((item) => {
      if (isUnavailable(item.id, quantities)) {
        removeItem(item);
      }
      if (isLessAvailable(item.id, item.quantity, quantities)) {
        adjustItem(item.id, quantities[item.id].quantity);
      }
    });
  };

  return (
    <>
      <Seo title="Ολοκλήρωση αγορών" />
      <Container withPadding={true} containerClass={fullHeightContainer}>
        <header className={header}>
          <Link aria-label={site.siteMetadata.title} className={brand} to="/">
            <Logo aria-label={site.siteMetadata.title} />
          </Link>
          <h1 className={title}>ΟΛΟΚΛΗΡΩΣΗ ΑΓΟΡΩΝ</h1>
        </header>
        <div className={container}>
          <div className={leftContainer}>
            <div className={titleContainer}>
              <span>ΚΑΛΑΘΙ</span>
              <Circle style={circleStyle}>{cart.length}</Circle>
            </div>
            <Cart
              quantities={quantities}
              submitCoupon={submitCoupon}
              coupon={{
                data: coupon.data,
                error: coupon.error,
                loading: coupon.loading,
              }}
            />
          </div>
          {cart && cart.length > 0 && (
            <div className={rightContainer}>
              {!isCartEligible && (
                <div className={error}>
                  <FiAlertTriangle size={'2em'} color={'#000'} />
                  <p className={infoText}>
                    Λυπούμαστε αλλά κανένα προϊόν στο καλάθι δεν είναι πλέον
                    διαθέσιμο.
                    <br />
                    <br />
                    Μπορείς να επιστρέψεις στην αρχική σελίδα για να συνεχίσεις
                    τις αγορές σου. Το καλάθι θα αδειάσει αυτόματα.
                  </p>
                  <PrimaryButton onClick={emptyAndContinue}>
                    Επιστροφή στις αγορές
                  </PrimaryButton>
                </div>
              )}
              {isCartEligible && (isAnyUnavailable || isAnyLessAvailable) && (
                <div className={error}>
                  <FiAlertTriangle size={'2em'} color={'#000'} />
                  <p className={infoText}>
                    Λυπούμαστε αλλά κάποιο από τα προϊόντα στο καλάθι δεν είναι
                    πλέον διαθέσιμο.
                    <br />
                    <br />
                    Μπορείς να συνεχίσεις με την ολοκλήρωση της αγοράς σου και
                    θα αφαιρέσουμε εμείς όποιο προϊόν δεν είναι πλέον διαθέσιμο.
                  </p>
                  <PrimaryButton onClick={handleCartChanges}>
                    Συνέχεια
                  </PrimaryButton>
                </div>
              )}
              {isCartEligible && !(isAnyUnavailable || isAnyLessAvailable) && (
                <section className={simpleContainer}>
                  {!loading && !isLoggedIn && (
                    <div className={signupContainer}>
                      <h2 className={signupTitle}>
                        Κάντε εγγραφή ή συνδεθείτε για να ολοκληρώνετε ακόμα πιο
                        γρήγορα τις αγορές σας!
                      </h2>
                      <Link className={btnLinkSecondary} to="/app/account">
                        ΣΥΝΔΕΣΗ/ΕΓΓΡΑΦΗ
                      </Link>
                    </div>
                  )}
                  {!loading && isCash !== null && (
                    <div className={formContainer}>
                      <CheckoutForm
                        isLoggedIn={isLoggedIn}
                        user={user}
                        onSubmit={submitOrder}
                        isCash={isCash}
                        setIsCash={setIsCash}
                        totals={totals}
                        actions={
                          <>
                            <LoadingPrimaryButton
                              type="submit"
                              loading={isLoading}
                              id="submit-order"
                              buttonClass={btnCheckout}
                            >
                              ΟΛΟΚΛΗΡΩΣΗ ΠΑΡΑΓΓΕΛΙΑΣ
                            </LoadingPrimaryButton>
                            {orderError && (
                              <ErrorMessage>
                                Κάτι πήγε στραβά με την παραγγελία… Προσπάθησε
                                ξανά ή κάλεσε μας στο τηλέφωνο επικοινωνίας.
                              </ErrorMessage>
                            )}
                          </>
                        }
                      />
                    </div>
                  )}
                </section>
              )}
            </div>
          )}
        </div>
      </Container>
      <footer>
        <Copyrights site={site.siteMetadata.site} />
      </footer>
    </>
  );
};

export default Checkout;
