import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select, { components } from 'react-select';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import { validateGreekPhone } from 'utilities/greekPhoneValidators';

// import validateTaxId from 'utilities/taxIdValidator';
import {
  form,
  title,
  row,
  inputContainer,
  checkboxContainer,
  input,
  select,
  label,
  area,
  addressSelect,
  primaryText,
  secondaryText,
  selectAddressText,
} from './CheckoutForm.module.scss';
import { container, checkmark } from 'styles/components/radio.module.scss';

// const marginTop = { marginTop: '1.5rem' };
const paymentTypeStyle = {
  marginTop: '1.5rem',
  minHeight: '7.5rem',
  alignItems: 'initial',
};

const Option = (props) => {
  const marginTop = { marginTop: '1rem' };
  return (
    <components.Option {...props}>
      {props.data && props.data.city ? (
        <>
          <p className={primaryText}>
            {props.data.street} {props.data.streetNumber}, {props.data.city}{' '}
            {props.data.postalCode}
          </p>
          <p
            className={secondaryText}
          >{`${props.data.nameAtBell}, όροφος ${props.data.floor}`}</p>
        </>
      ) : (
        <>
          <p className={primaryText} style={marginTop}>
            Εισαγωγή νέας διεύθυνσης…
          </p>
        </>
      )}
    </components.Option>
  );
};

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    {props.data && props.data.city ? (
      <>
        <p className={primaryText}>
          {props.data.street} {props.data.streetNumber}, {props.data.city}{' '}
          {props.data.postalCode}
        </p>
        <p
          className={secondaryText}
        >{`${props.data.nameAtBell}, όροφος ${props.data.floor}`}</p>
      </>
    ) : (
      <>
        <p className={primaryText}>Εισαγωγή νέας διεύθυνσης…</p>
      </>
    )}
  </components.SingleValue>
);

// const CompanyOption = (props) => {
//   const marginTop = { marginTop: '1rem' };
//   return (
//     <components.Option {...props}>
//       {props.data && props.data.companyCity ? (
//         <>
//           <p className={primaryText}>
//             {`${props.data.companyName}, ${props.data.profession}`}
//           </p>
//           <p
//             className={secondaryText}
//           >{`${props.data.companyStreet} ${props.data.companyStreetNumber}, ${props.data.companyCity} ${props.data.companyPostalCode}`}</p>
//         </>
//       ) : (
//         <>
//           <p className={primaryText} style={marginTop}>
//             Εισαγωγή νέας επιχείρησης…
//           </p>
//         </>
//       )}
//     </components.Option>
//   );
// };

// const CompanySingleValue = (props) => (
//   <components.SingleValue {...props}>
//     {props.data && props.data.companyCity ? (
//       <>
//         <p className={primaryText}>
//           {`${props.data.companyName}, ${props.data.profession}`}
//         </p>
//         <p
//           className={secondaryText}
//         >{`${props.data.companyStreet} ${props.data.companyStreetNumber}, ${props.data.companyCity} ${props.data.companyPostalCode}`}</p>
//       </>
//     ) : (
//       <>
//         <p className={primaryText}>Εισαγωγή νέας επιχείρησης…</p>
//       </>
//     )}
//   </components.SingleValue>
// );

const CheckoutForm = ({
  isLoggedIn,
  user,
  onSubmit,
  actions,
  isCash,
  setIsCash,
  totals,
}) => {
  const addressOptions =
    user && user.addresses
      ? [
          ...user.addresses.map((address) => ({
            ...address,
            label: `${address.street} ${address.streetNumber}, ${address.city} ${address.postalCode}`,
            value: address,
          })),
          { label: 'Εισαγωγή νέας διεύθυνσης…', value: '' },
        ]
      : [{ label: 'Εισαγωγή νέας διεύθυνσης…', value: '' }];
  const companyAddressOptions =
    user && user.companyAddresses
      ? [
          ...user.companyAddresses.map((address) => ({
            ...address,
            label: `${address.companyName}, ${address.profession}`,
            value: address,
          })),
          { label: 'Εισαγωγή νέας επιχείρησης…', value: '' },
        ]
      : [{ label: 'Εισαγωγή νέας επιχείρησης…', value: '' }];

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      address: addressOptions[0],
      companyAddress: companyAddressOptions[0],
      // invoice: !!(user && user.taxId),
      paymentType: isCash === true ? 'cash' : 'card',
    },
  });
  // const watchInvoice = watch('invoice');
  const watchAddress = watch('address');
  // const watchCompanyAddress = watch('companyAddress');
  const watchPaymentType = watch('paymentType');

  useEffect(() => {
    if ((watchPaymentType === 'cash') !== isCash) {
      setIsCash(watchPaymentType === 'cash');
    }
  }, [watchPaymentType, setIsCash, isCash]);

  return (
    <form className={form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <>
        <h4 className={title}>Στοιχεία Επικοινωνίας</h4>
        <div className={row}>
          <div className={inputContainer}>
            <label className={label} htmlFor="firstname">
              Όνομα
            </label>
            <input
              className={input}
              id="firstname"
              {...register('firstname', {
                required: 'Το πεδίο Όνομα είναι υποχρεωτικό',
              })}
              type="text"
              aria-label="Όνομα"
              defaultValue={user?.firstname}
            />
            {errors.firstname && (
              <ErrorMessage>{errors.firstname.message}</ErrorMessage>
            )}
          </div>
          <div className={inputContainer}>
            <label className={label} htmlFor="lastname">
              Επώνυμο
            </label>
            <input
              className={input}
              id="lastname"
              {...register('lastname', {
                required: 'Το πεδίο Επώνυμο είναι υποχρεωτικό',
              })}
              type="text"
              aria-label="Επώνυμο"
              defaultValue={user?.lastname}
            />
            {errors.lastname && (
              <ErrorMessage>{errors.lastname.message}</ErrorMessage>
            )}
          </div>
        </div>

        <div className={row}>
          <div className={inputContainer}>
            <label className={label} htmlFor="email">
              Email
            </label>
            <input
              className={input}
              id="email"
              {...register('email', {
                required: 'Το email είναι υποχρεωτικό',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Λάθος μορφή email',
                },
              })}
              type="email"
              aria-label="Email"
              defaultValue={user?.email}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </div>
          <div className={inputContainer}>
            <label className={label} htmlFor="contactPhone">
              Τηλέφωνο
            </label>
            <input
              className={input}
              id="contactPhone"
              {...register('contactPhone', {
                required: 'Το πεδίο Τηλέφωνο είναι υποχρεωτικό',
                validate: (value) =>
                  validateGreekPhone(value) ? true : 'Λάθος μορφή τηλεφώνου',
              })}
              type="text"
              aria-label="Τηλέφωνο επικοινωνίας"
              defaultValue={user?.contactPhone}
            />
            {errors.contactPhone && (
              <ErrorMessage>{errors.contactPhone.message}</ErrorMessage>
            )}
          </div>
        </div>
      </>

      <h4 className={title}>Διεύθυνση παράδοσης</h4>
      {isLoggedIn && user.addresses && user.addresses.length > 0 && (
        <>
          <p className={selectAddressText}>
            Επιλέξτε από τις αποθηκευμένες διευθύνσεις
          </p>
          <Controller
            control={control}
            render={({ field }) => (
              <Select
                id="address"
                className={addressSelect}
                classNamePrefix="react-select-address"
                components={{ Option, SingleValue }}
                options={addressOptions}
                isSearchable={false}
                styles={{
                  option: (base, { isDisabled, isFocused, isSelected }) => ({
                    ...base,
                    minHeight: '4rem',
                    color: '#000',
                    backgroundColor: isDisabled
                      ? null
                      : isSelected
                      ? '#e3e3e3'
                      : isFocused
                      ? '#f3f3f1'
                      : null,
                    ':active': {
                      ...base[':active'],
                      backgroundColor:
                        !isDisabled && (isSelected ? '#e3e3e3' : '#f3f3f1'),
                    },
                  }),
                }}
                {...field}
              />
            )}
            name="address"
          />
        </>
      )}

      {(!isLoggedIn || watchAddress.value === '') && (
        <>
          <div className={row}>
            <div className={inputContainer}>
              <label className={label} htmlFor="region">
                Περιοχή
              </label>
              <select id="region" className={select} {...register('region')}>
                <option value="Αττική">Αττική</option>
                <option value="Πελοπόννησος">Πελοπόννησος</option>
                <option value="Ιόνια Νησιά">Ιόνια Νησιά</option>
                <option value="Στερεά Ελλάδα">Στερεά Ελλάδα</option>
                <option value="Δυτική Ελλάδα">Δυτική Ελλάδα</option>
                <option value="Ήπειρος">Ήπειρος</option>
                <option value="Θεσσαλία">Θεσσαλία</option>
                <option value="Δυτική Μακεδονία">Δυτική Μακεδονία</option>
                <option value="Κεντρική Μακεδονία">Κεντρική Μακεδονία</option>
                <option value="Ανατολική Μακεδονία και Θράκη">
                  Ανατολική Μακεδονία και Θράκη
                </option>
                <option value="Βόρειο Αιγαίο">Βόρειο Αιγαίο</option>
                <option value="Νότιο Αιγαίο">Νότιο Αιγαίο</option>
                <option value="Κρήτη">Κρήτη</option>
              </select>
            </div>
            <div className={inputContainer}>
              <label className={label} htmlFor="city">
                Πόλη
              </label>
              <input
                className={input}
                id="city"
                {...register('city', {
                  required: 'Το πεδίο Πόλη είναι υποχρεωτικό',
                })}
                type="text"
                aria-label="Πόλη"
              />
              {errors.city && (
                <ErrorMessage>{errors.city.message}</ErrorMessage>
              )}
            </div>
          </div>

          <div className={row}>
            <div className={inputContainer}>
              <label className={label} htmlFor="street">
                Οδός
              </label>
              <input
                className={input}
                id="street"
                {...register('street', {
                  required: 'Το πεδίο Οδός είναι υποχρεωτικό',
                })}
                type="text"
                aria-label="Οδός"
              />
              {errors.street && (
                <ErrorMessage>{errors.street.message}</ErrorMessage>
              )}
            </div>
            <div className={inputContainer}>
              <label className={label} htmlFor="streetNumber">
                Αριθμός
              </label>
              <input
                className={input}
                id="streetNumber"
                {...register('streetNumber', {
                  required: 'Το πεδίο Αριθμός είναι υποχρεωτικό',
                })}
                type="text"
                aria-label="Αριθμός"
              />
              {errors.streetNumber && (
                <ErrorMessage>{errors.streetNumber.message}</ErrorMessage>
              )}
            </div>
          </div>

          <div className={row}>
            <div className={inputContainer}>
              <label className={label} htmlFor="nameAtBell">
                Κουδούνι
              </label>
              <input
                className={input}
                id="nameAtBell"
                {...register('nameAtBell')}
                type="text"
                aria-label="Όνομα στο κουδούνι"
              />
              {errors.nameAtBell && (
                <ErrorMessage>{errors.nameAtBell.message}</ErrorMessage>
              )}
            </div>
            <div className={inputContainer}>
              <label className={label} htmlFor="floor">
                Όροφος
              </label>
              <input
                className={input}
                {...register('floor')}
                id="floor"
                type="text"
                aria-label="Όροφος"
              />
              {errors.floor && (
                <ErrorMessage>{errors.floor.message}</ErrorMessage>
              )}
            </div>
          </div>

          <div className={row}>
            <div className={inputContainer}>
              <label className={label} htmlFor="postalCode">
                Τ.Κ.
              </label>
              <input
                className={input}
                id="postalCode"
                {...register('postalCode', {
                  required: 'Το πεδίο Τ.Κ. είναι υποχρεωτικό',
                  pattern: {
                    value: /\b\d{5}\b/,
                    message: 'Λάθος μορφή Τ.Κ.',
                  },
                })}
                type="text"
                aria-label="Τ.Κ."
              />
              {errors.postalCode && (
                <ErrorMessage>{errors.postalCode.message}</ErrorMessage>
              )}
            </div>
          </div>
        </>
      )}

      <div className={row} style={{ marginBottom: '2.5rem' }}>
        <div className={inputContainer} style={{ flexBasis: '100%' }}>
          <label className={label} htmlFor="comments">
            Σχόλια παραγγελίας
          </label>
          <textarea
            className={area}
            id="comments"
            {...register('comments')}
            rows="5"
            cols="40"
          />
        </div>
      </div>

      <h4 className={title}>Τρόπος πληρωμής</h4>
      <div style={paymentTypeStyle} className={row}>
        <div className={inputContainer} style={{ flexBasis: '100%' }}>
          {/* <label className={container} htmlFor="bank">
            <span>Κατάθεση σε Τραπεζικό λογαριασμό</span>
            <input
              id="bank"
              {...register('paymentType')}
              aria-label="bank"
              type="radio"
              value="bank"
            />
            <span className={checkmark}></span>
          </label> */}
          <label className={container} htmlFor="cash">
            <span>{`Πληρωμή με αντικαταβολή (+${
              (totals && totals.cashShippingFee) || ''
            }€)`}</span>
            <input
              id="cash"
              {...register('paymentType')}
              aria-label="cash"
              type="radio"
              value="cash"
            />
            <span className={checkmark}></span>
          </label>
          <label
            className={container}
            style={{ marginBottom: '0.25rem' }}
            htmlFor="card"
          >
            <span>Πληρωμή με κάρτα</span>
            <input
              id="card"
              {...register('paymentType')}
              aria-label="card"
              type="radio"
              value="card"
            />
            <span className={checkmark}></span>
          </label>
          <StaticImage
            src="../../images/payment-methods-horizontal.png"
            alt="Alpha e-commerce payment methods, credit cards. Visa, mastercard, maestro, amex, masterpass"
          />
        </div>
      </div>
      <hr style={{ marginTop: '2.5rem', marginBottom: '2rem' }} />
      {/* <div className={checkboxContainer}>
        <label className={container} htmlFor="invoice">
          <span>Αγορά με τιμολόγιο</span>
          <input
            className={input}
            id="invoice"
            type="checkbox"
            {...register('invoice')}
          />
          <span className={checkmark}></span>
        </label>
      </div>
      {watchInvoice === true && (
        <section style={marginTop}>
          <h4 className={title}>Στοιχεία επιχείρησης</h4>

          {isLoggedIn &&
            user.companyAddresses &&
            user.companyAddresses.length > 0 && (
              <>
                <p className={selectAddressText}>
                  Επιλέξτε από τις αποθηκευμένες επιχειρήσεις
                </p>
                <Controller
                  control={control}
                  render={({ field }) => <Select {...field} />}
                  name="companyAddress"
                  id="companyAddress"
                  className={addressSelect}
                  classNamePrefix="react-select-address"
                  components={{
                    Option: CompanyOption,
                    SingleValue: CompanySingleValue,
                  }}
                  options={companyAddressOptions}
                  isSearchable={false}
                  styles={{
                    option: (base, { isDisabled, isFocused, isSelected }) => ({
                      ...base,
                      minHeight: '4rem',
                      color: '#000',
                      backgroundColor: isDisabled
                        ? null
                        : isSelected
                        ? '#e3e3e3'
                        : isFocused
                        ? '#f3f3f1'
                        : null,
                      ':active': {
                        ...base[':active'],
                        backgroundColor:
                          !isDisabled && (isSelected ? '#e3e3e3' : '#f3f3f1'),
                      },
                    }),
                  }}
                />
              </>
            )}

          {(!isLoggedIn || watchCompanyAddress.value === '') && (
            <>
              <div className={row}>
                <div className={inputContainer}>
                  <label className={label} htmlFor="companyRegion">
                    Περιοχή
                  </label>
                  <select
                    id="companyRegion"
                    {...register('companyRegion')}
                    className={select}
                  >
                    <option value="Αττική">Αττική</option>
                    <option value="Πελοπόννησος">Πελοπόννησος</option>
                    <option value="Ιόνια Νησιά">Ιόνια Νησιά</option>
                    <option value="Στερεά Ελλάδα">Στερεά Ελλάδα</option>
                    <option value="Δυτική Ελλάδα">Δυτική Ελλάδα</option>
                    <option value="Ήπειρος">Ήπειρος</option>
                    <option value="Θεσσαλία">Θεσσαλία</option>
                    <option value="Δυτική Μακεδονία">Δυτική Μακεδονία</option>
                    <option value="Κεντρική Μακεδονία">
                      Κεντρική Μακεδονία
                    </option>
                    <option value="Ανατολική Μακεδονία και Θράκη">
                      Ανατολική Μακεδονία και Θράκη
                    </option>
                    <option value="Βόρειο Αιγαίο">Βόρειο Αιγαίο</option>
                    <option value="Νότιο Αιγαίο">Νότιο Αιγαίο</option>
                    <option value="Κρήτη">Κρήτη</option>
                  </select>
                </div>
                <div className={inputContainer}>
                  <label className={label} htmlFor="companyCity">
                    Πόλη
                  </label>
                  <input
                    className={input}
                    id="companyCity"
                    {...register('companyCity', {
                      required: 'Το πεδίο Πόλη είναι υποχρεωτικό',
                    })}
                    type="text"
                    aria-label="Πόλη"
                  />
                  {errors.companyCity && (
                    <ErrorMessage>{errors.companyCity.message}</ErrorMessage>
                  )}
                </div>
              </div>

              <div className={row}>
                <div className={inputContainer}>
                  <label className={label} htmlFor="companyStreet">
                    Οδός
                  </label>
                  <input
                    className={input}
                    id="companyStreet"
                    {...register('companyStreet', {
                      required: 'Το πεδίο Οδός είναι υποχρεωτικό',
                    })}
                    type="text"
                    aria-label="Οδός"
                  />
                  {errors.companyStreet && (
                    <ErrorMessage>{errors.companyStreet.message}</ErrorMessage>
                  )}
                </div>
                <div className={inputContainer}>
                  <label className={label} htmlFor="companyStreetNumber">
                    Αριθμός
                  </label>
                  <input
                    className={input}
                    id="companyStreetNumber"
                    {...register('companyStreetNumber', {
                      required: 'Το πεδίο Αριθμός είναι υποχρεωτικό',
                    })}
                    type="text"
                    aria-label="Αριθμός"
                  />
                  {errors.companyStreetNumber && (
                    <ErrorMessage>
                      {errors.companyStreetNumber.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>

              <div className={row}>
                <div className={inputContainer}>
                  <label className={label} htmlFor="companyPostalCode">
                    Τ.Κ.
                  </label>
                  <input
                    className={input}
                    id="companyPostalCode"
                    {...register('companyPostalCode', {
                      required: 'Το πεδίο Τ.Κ. είναι υποχρεωτικό',
                      pattern: {
                        value: /\b\d{5}\b/,
                        message: 'Λάθος μορφή Τ.Κ.',
                      },
                    })}
                    type="text"
                    aria-label="Τ.Κ."
                  />
                  {errors.companyPostalCode && (
                    <ErrorMessage>
                      {errors.companyPostalCode.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>

              <div className={row}>
                <div className={inputContainer}>
                  <label className={label} htmlFor="taxId">
                    ΑΦΜ
                  </label>
                  <input
                    className={input}
                    id="taxId"
                    {...register('taxId', {
                      required: 'Το πεδίο ΑΦΜ είναι υποχρεωτικό',
                      validate: (value) =>
                        validateTaxId(value) ? true : 'Λάθος ΑΦΜ',
                    })}
                    type="text"
                    aria-label="ΑΦΜ"
                  />
                  {errors.taxId && (
                    <ErrorMessage>{errors.taxId.message}</ErrorMessage>
                  )}
                </div>
                <div className={inputContainer}>
                  <label className={label} htmlFor="taxOffice">
                    ΔΟΥ
                  </label>
                  <input
                    className={input}
                    id="taxOffice"
                    {...register('taxOffice', {
                      required: 'Το πεδίο ΔΟΥ είναι υποχρεωτικό',
                    })}
                    type="text"
                    aria-label="ΔΟΥ"
                  />
                  {errors.taxOffice && (
                    <ErrorMessage>{errors.taxOffice.message}</ErrorMessage>
                  )}
                </div>
              </div>

              <div className={row}>
                <div className={inputContainer}>
                  <label className={label} htmlFor="companyName">
                    Επωνυμία
                  </label>
                  <input
                    className={input}
                    id="companyName"
                    {...register('companyName', {
                      required:
                        'Το πεδίο Επωνυμία επιχείρησης είναι υποχρεωτικό',
                    })}
                    type="text"
                    aria-label="Επωνυμία επιχείρησης"
                  />
                  {errors.companyName && (
                    <ErrorMessage>{errors.companyName.message}</ErrorMessage>
                  )}
                </div>
                <div className={inputContainer}>
                  <label className={label} htmlFor="profession">
                    Επάγγελμα
                  </label>
                  <input
                    className={input}
                    id="profession"
                    {...register('profession', {
                      required: 'Το πεδίο Επάγγελμα είναι υποχρεωτικό',
                    })}
                    type="text"
                    aria-label="Επάγγελμα"
                  />
                  {errors.profession && (
                    <ErrorMessage>{errors.profession.message}</ErrorMessage>
                  )}
                </div>
              </div>
            </>
          )}
        </section>
      )} */}

      <div className={checkboxContainer}>
        <label
          style={{ marginBottom: '0.5rem' }}
          className={container}
          htmlFor="termsAccepted"
        >
          <span>
            Αποδέχομαι τους{' '}
            <b>
              <Link to={'/oroi-xrhshs'}>όρους χρήσης</Link>
            </b>
          </span>
          <input
            className={input}
            id="termsAccepted"
            type="checkbox"
            {...register('termsAccepted', {
              required:
                'Πρέπει να αποδεχτείς τους όρους χρήσης της ιστοσελίδας',
            })}
          />
          <span className={checkmark}></span>
        </label>
      </div>
      {errors.termsAccepted && (
        <ErrorMessage>{errors.termsAccepted.message}</ErrorMessage>
      )}
      {actions}
    </form>
  );
};

export default CheckoutForm;
